import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

interface Option {
    option: string
    isAnswer: boolean | string
}

export interface postSurveys {
    surveyId: string
    _id: string
    category: string
    type: string
    order: number
    mustAnswer: boolean
    subject: string
    options: Option[]
    hadAsked: boolean
    eventId: string
}

export interface postSurveyStates {
    postSurveys: postSurveys[]
    error: string | null
}

export const onGetAllPostSurvey = createAsyncThunk(
    'survey/getAllPostSurvey',
    async ({ eventId }: { eventId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/post-survey/all/${eventId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: postSurveyStates = { postSurveys: [], error: null }

const postSurveySlice = createSlice({
    name: 'postSurvey',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onGetAllPostSurvey.fulfilled, (state, action) => {
            state.postSurveys = action.payload.postSurveys
            state.error = null
        })
        builder.addCase(onGetAllPostSurvey.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
    },
})

export default postSurveySlice.reducer
