import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

interface Result {
    option: string
    percent: number
    count: number
}

interface ResultResponse {
    result: Result[]
    total: number
    correct: string[]
}
interface MiddleResultResponse {
    surveyId: string
    total: number
    result: { [key: string]: number }
}

export interface ResultStates extends ResultResponse {
    middleResult: MiddleResultResponse[]
    error: string | null
}

export const onGetSurveyResult = createAsyncThunk<
    { resultResponse: ResultResponse },
    { _id: string; surveyId: string }
>('result/getSurveyResult', async (arg, { rejectWithValue }) => {
    try {
        const res = await axios.get(`/result/${arg._id}&&&${arg.surveyId}`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onGetMiddleSurveyResult = createAsyncThunk<
    { middleResultResponse: MiddleResultResponse[]; correct: string[] },
    { eventId: string }
>('result/getMiddleSurveyResult', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.get(`/result/middle/${args.eventId}`)

        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

const initialState: ResultStates = { middleResult: [], result: [], total: 0, correct: [], error: null }

const resultSlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        removeResult(state, _action) {
            state.error = null
            state.result = []
            state.total = 0
            state.correct = []
        },
        removeMiddleResult(state, _action) {
            state.error = null
            state.middleResult = []
            state.correct = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(onGetSurveyResult.fulfilled, (state, action) => {
            const resultResponse = action.payload.resultResponse
            state.result = resultResponse.result
            state.total = resultResponse.total
            state.correct = resultResponse.correct
            state.error = null
        })
        builder.addCase(onGetSurveyResult.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
            state.result = []
            state.total = 0
            state.correct = []
        })
        builder.addCase(onGetMiddleSurveyResult.fulfilled, (state, action) => {
            const resultResponse = action.payload.middleResultResponse
            const correct = action.payload.correct
            state.correct = correct
            state.middleResult = resultResponse

            state.error = null
        })
        builder.addCase(onGetMiddleSurveyResult.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
            state.result = []
            state.total = 0
            state.correct = []
        })
    },
})

export const { removeResult, removeMiddleResult } = resultSlice.actions

export default resultSlice.reducer
