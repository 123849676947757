export const Links = {
    LOADING: '/json/loader.json',
    LUCKY_DRAW: '/img/LuckyDraw_1_600px.gif',
    LUCKY_DRAW_RESULT: '/img/LuckyDraw_2_600px.gif',
    UNLOCK: '/img/UnlockMotion_1_600px.gif',
    UNLOCK_RESULT: '/img/UnlockMotion_2_600px.png',
    UNLOCK_LOCK: '/img/lock.svg',
    UNLOCK_ICON: '/img/resultUnlock.svg',
    UNLOCK_BG: '/img/lock-bg.png',
    DONE: '/img/done.gif',
}

export const LandingPageImages = {
    LOGO_GOOGLE_GRAY: '/img/logo_google_gray.svg',
    LOGO_GOOGLE_COLOR: '/img/google_color.svg',
    NETWORK: '/img/icon_group.svg',
    SURVEY_DONE: '/img/survey_done.gif',
    AGENDA_TITLE_MOBILE: '/img/bg1_mo.svg',
    SPEAKER_TITLE_MOBILE: '/img/bg2_mo.svg',
    FAQ_TITLE_MOBILE: '/img/bg3_mo.svg',
    EVENT_TITLE_Search_PC: '/img/title_s_pc.svg',
    EVENT_TITLE_Measurement_PC: '/img/title_m_pc.svg',
    EVENT_TITLE_Search_mobile: '/img/title_s_mo.svg',
    EVENT_TITLE_Measurement_mobile: '/img/title_m_mo.svg',
    EVENT_KV: '/img/kv_0420.gif',
    AGENDA_SPEAKER_CARD_DOT: '/img/card-dot.svg',
    LOGO_Google_AutomationUnboxed: '/img/Google_AutomationUnboxed.svg',
}
