import React, { useState, useEffect, createContext, Dispatch, SetStateAction } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { IntlProvider } from 'react-intl'

import { store, persistor } from './state'

import App from './App'
import zh from './i18n/zh.json'
import './styles/index.scss'

export type LocaleProps = {
    setLocale: Dispatch<SetStateAction<string>>
    locale: string
    jsonData: any
}

export const localeContext = createContext<LocaleProps | null>(null)

// function demoAsyncCall() {
//     return new Promise((resolve) => setTimeout(() => resolve(null), 1100))
// }

const Root = () => {
    const [locale, setLocale] = useState('zh')
    const [messages, setMessages] = useState(zh)
    // const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     demoAsyncCall().then(() => setLoading(false))
    // }, [loading])

    /**
     * 根據 locale 設定的指標，判斷該讀取哪個語言設定檔
     */
    useEffect(() => {
        if (locale.includes('zh')) setMessages(zh)
        else setMessages(zh)

        document.documentElement.lang = locale
    }, [locale])

    // if (loading) {
    //     return null
    // }

    return (
        <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <localeContext.Provider value={{ locale, setLocale, jsonData: messages }}>
                        <App />
                    </localeContext.Provider>
                </PersistGate>
            </Provider>
        </IntlProvider>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'))
