import React from 'react'
import '@lottiefiles/lottie-player'

import { Links } from '../../utils/links'
import '../../styles/loader.scss'

const Loader = () => {
    return (
        <div id="loader">
            <div id="lottie">
                <lottie-player autoplay loop mode="normal" src={Links.LOADING} id="lottiePlayer"></lottie-player>
            </div>
        </div>
    )
}

export default Loader
