import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

interface consoleState {
    userAward: { code: string; name: string }[]
    uploadCount: number
    usersCount: number
    deletedCount: number
    error: string | null
}

export const onUploadUsers = createAsyncThunk(
    'console/uploadUsers',
    async ({ dataForm }: { dataForm: FormData }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/console/upload/users`, dataForm)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDropUsers = createAsyncThunk('console/dropUsers', async (_args, { rejectWithValue }) => {
    try {
        const res = await axios.delete(`/console/drop/users`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onGetMiddleSurveyAward = createAsyncThunk(
    'console/getMiddleSurveyAward',
    async (_args, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/award/users`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeliverMiddleSurvey = createAsyncThunk(
    'console/deliverMiddleSurvey',
    async (args: { code: string; surveyId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/deliver/survey/${args.code}&&&${args.surveyId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeliverSocketDisconnect = createAsyncThunk(
    'console/deliverSocketDisconnect',
    async (args: { code: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/deliver/socketdisconnet/${args.code}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: consoleState = {
    userAward: [],
    usersCount: 0,
    uploadCount: 0,
    deletedCount: 0,
    error: null,
}

export const consoleSlice = createSlice({
    name: 'consoleSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onUploadUsers.pending, (state, _action) => {
            state.usersCount = 0
            state.uploadCount = 0
            state.deletedCount = 0
            state.error = null
        })
        builder.addCase(onUploadUsers.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onUploadUsers.fulfilled, (state, action) => {
            state.usersCount = action.payload.usersCount
            state.uploadCount = action.payload.uploadSuccessUsers
            state.deletedCount = 0
            state.error = null
        })

        builder.addCase(onDropUsers.pending, (state, _action) => {
            state.usersCount = 0
            state.uploadCount = 0
            state.deletedCount = 0
            state.error = null
        })
        builder.addCase(onDropUsers.fulfilled, (state, action) => {
            state.deletedCount = action.payload.deletedCount
            state.usersCount = action.payload.usersCount
            state.uploadCount = 0
            state.error = null
        })
        builder.addCase(onDropUsers.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onGetMiddleSurveyAward.fulfilled, (state, action) => {
            state.userAward = action.payload.userAward
            state.error = null
        })
        builder.addCase(onGetMiddleSurveyAward.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onDeliverMiddleSurvey.fulfilled, (state, action) => {
            state.error = null
        })
        builder.addCase(onDeliverMiddleSurvey.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onDeliverSocketDisconnect.fulfilled, (state, action) => {
            state.error = null
        })
        builder.addCase(onDeliverSocketDisconnect.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
    },
})

export default consoleSlice.reducer
