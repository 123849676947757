import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'
import { removeDup } from '../../lib/fn'
import { RepliedDetail } from '../../components/main/interactiveSec/InteractiveSec'

export interface Message {
    _id: string
    from: {
        _id: string
        name: string
        avatar: string
        company: string
        googler: boolean
        helper: boolean
    }
    type: string
    content: string
    approved: boolean
    createTime: number
    room: string
    to: RepliedDetail | null
}

interface chatState {
    error: string | null
    messages: Message[]
    loading: boolean
    comingData: boolean
}

export const onGetHistoryMessages = createAsyncThunk(
    'chat/getHistoryMessages',
    async (args: { _id: string; room: string }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/chat/historyMessages/${args._id}`, {
                messageCount: process.env.REACT_APP_GET_MESSAGE_COUNT,
                room: args.room,
            })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetRestHistoryMessages = createAsyncThunk(
    'chat/getRestHistoryMessages',
    async (args: { _id: string; localMessageCount: number; room: string }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/chat/restHistoryMessages/${args._id}`, {
                messageCount: process.env.REACT_APP_GET_MESSAGE_COUNT,
                localMessageCount: args.localMessageCount,
                room: args.room,
            })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: chatState = {
    messages: [],
    error: null,
    loading: false,
    comingData: false,
}

export const charSlice = createSlice({
    name: 'charSlice',
    initialState,
    reducers: {
        addLocalMessage(state, action) {
            state.messages = [...state.messages, action.payload]
        },
        updateLocalMessage(state, action) {
            let mergedMessageArr: Message[] = [...state.messages, action.payload]

            mergedMessageArr = removeDup<Message>(mergedMessageArr, (m) => m._id)

            state.messages = mergedMessageArr
        },
    },
    extraReducers: (builder) => {
        builder.addCase(onGetHistoryMessages.pending, (state, _action) => {
            state.loading = true
        })
        builder.addCase(onGetHistoryMessages.fulfilled, (state, action) => {
            state.messages = action.payload.messages.reverse()
            state.error = null
            state.loading = false
            state.comingData = action.payload.messages.length > 0
        })
        builder.addCase(onGetHistoryMessages.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onGetRestHistoryMessages.pending, (state, _action) => {
            state.loading = true
        })
        builder.addCase(onGetRestHistoryMessages.fulfilled, (state, action) => {
            const reverseMessages = action.payload.messages.reverse()
            state.messages = [...reverseMessages, ...state.messages]
            state.error = null
            state.loading = false
            state.comingData = action.payload.messages.length > 0
        })
        builder.addCase(onGetRestHistoryMessages.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
    },
})

export const { addLocalMessage, updateLocalMessage } = charSlice.actions
export default charSlice.reducer
