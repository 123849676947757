import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'
import { Survey } from './surveySlice'

export interface CronJobsFieldsValue {
    _id?: string
    name: string
    message: string
    taskType: string
    timeStamp: number
    eventId: string
    eventStartTime: number
    surveyId?: string
    payload: number
}

export interface Jobs {
    _id: string
    name: string
    implementTime: number
    eventId: string
    taskType: string
    completed: boolean
    message: string
    surveyId: Survey | null
    payload: number
}

interface JobsState {
    jobs: Jobs[]
    error: string | null
}

export const onGetJobsDefine = createAsyncThunk(
    'jobs/getJobsDefine',
    async (args: { jobs: CronJobsFieldsValue }, { rejectWithValue }) => {
        try {
            const res = await axios.post('/jobs/define', args)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetJobsRedefine = createAsyncThunk('jobs/getJobsRedefine', async (_args, { rejectWithValue }) => {
    try {
        const res = await axios.get(`/jobs/redefine`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onPatchJob = createAsyncThunk(
    'jobs/patchJob',
    async ({ jobs }: { jobs: CronJobsFieldsValue }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/jobs/job`, { jobs })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeleteJob = createAsyncThunk(
    'jobs/deleteJob',
    async ({ _id, name }: { _id: string; name: string }, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`/jobs/job/${_id}&${name}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onUploadCronJobs = createAsyncThunk(
    'jobs/uploadCronJobs',
    async ({ dataForm }: { dataForm: FormData }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/jobs/upload/jobs`, dataForm)

            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: JobsState = {
    jobs: [],
    error: null,
}

export const jobsSlice = createSlice({
    name: 'jobsSlice',
    initialState,
    reducers: {
        receiveJob(state, action) {
            const { _id } = action.payload

            const filteredJobs = state.jobs.filter((job) => job._id !== _id)

            state.jobs = filteredJobs
            state.error = null
        },
        removeJobsError(state, _action) {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(onGetJobsDefine.fulfilled, (state, action) => {
            state.jobs = [...state.jobs, action.payload.jobs]
            state.error = null
        })
        builder.addCase(onGetJobsDefine.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onGetJobsRedefine.fulfilled, (state, action) => {
            state.jobs = action.payload.jobs
            state.error = null
        })
        builder.addCase(onGetJobsRedefine.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })

        builder.addCase(onPatchJob.fulfilled, (state, action) => {
            const updatedJobs = state.jobs.filter((job) => {
                return job._id !== action.payload.job._id
            })

            updatedJobs.push(action.payload.job)
            updatedJobs.sort((a, b) => a.implementTime - b.implementTime)

            state.jobs = updatedJobs
            state.error = null
        })
        builder.addCase(onPatchJob.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })

        builder.addCase(onDeleteJob.fulfilled, (state, action) => {
            const updatedJobs = state.jobs.filter((job) => {
                return job._id !== action.payload.deletedId
            })
            state.jobs = updatedJobs
            state.error = null
        })
        builder.addCase(onDeleteJob.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onUploadCronJobs.fulfilled, (state, action) => {
            state.jobs = action.payload.jobs
            state.error = null
        })
        builder.addCase(onUploadCronJobs.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
    },
})

export const { receiveJob, removeJobsError } = jobsSlice.actions
export default jobsSlice.reducer
