import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

export const onGetEventDetail = createAsyncThunk('event/getEventDetail', async (_args, { rejectWithValue }) => {
    try {
        const res = await axios.get(`/event`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onGetEventTimeReset = createAsyncThunk('event/getEventTimeReset/', async (_args, { rejectWithValue }) => {
    try {
        const res = await axios.get(`/event/time/reset`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onUploadEvents = createAsyncThunk(
    'event/uploadEvents',
    async ({ dataForm }: { dataForm: FormData }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/event/upload/event`, dataForm)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export interface Event {
    _id: string
    name: string
    eventId: string
    channelId: string
    eventTime: number
    region: string
}

export interface EventStates {
    event: Event[]
    error: string | null
}

const initialState: EventStates = { event: [], error: null }

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onGetEventDetail.fulfilled, (state, action) => {
            state.event = action.payload.event
            state.error = null
        })
        builder.addCase(onGetEventDetail.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
            state.event = []
        })

        builder.addCase(onGetEventTimeReset.fulfilled, (state, action) => {
            state.event = action.payload.event
            state.error = null
        })
        builder.addCase(onGetEventTimeReset.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
            state.event = []
        })
        builder.addCase(onUploadEvents.fulfilled, (state, action) => {
            state.event = action.payload.events
            state.error = null
        })
        builder.addCase(onUploadEvents.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
            state.event = []
        })
    },
})

export default eventSlice.reducer
