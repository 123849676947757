import React, { useState, useEffect, Suspense, lazy, createContext, FC } from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'
import { Socket } from 'socket.io-client'
import ReactGA from 'react-ga'

import Loader from './components/ui/Loader'
import initialSkt from './lib/socket'

import './styles/App.scss'

const Console = lazy(() => import('./components/console/Console'))
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'))
const TheLayout = lazy(() => import('./containers/TheLayout'))

// const LandingPage = lazy(async () => {
//     const [moduleExports] = await Promise.all([
//         import('./components/LandingPage/LandingPage'),
//         new Promise((resolve) => setTimeout(resolve, parseInt(process.env.REACT_APP_LOADING_DELAY as string))),
//     ])

//     return moduleExports
// })

// const TheLayout = lazy(async () => {
//     const [moduleExports] = await Promise.all([
//         import('./containers/TheLayout'),
//         new Promise((resolve) => setTimeout(resolve, parseInt(process.env.REACT_APP_LOADING_DELAY as string))),
//     ])

//     return moduleExports
// })

export const socketContext = createContext({ socket: initialSkt, socketOn: false, socketId: '' })

const App: FC = () => {
    const [socket, setSocket] = useState<Socket>(initialSkt)
    const [socketOn, setSocketOn] = useState(false)
    const [socketId, setSocketId] = useState('')

    ReactGA.initialize(process.env.REACT_APP_GA_ID as string)
    ReactGA.pageview(window.location.pathname + window.location.search)

    useEffect(() => {
        if (initialSkt) setSocket(initialSkt)

        return () => {
            initialSkt.close()
        }
    }, [setSocket])

    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                console.log({ status: 'socket connected', socketId: socket.id })
                setSocketId(socket.id)
                setSocketOn(true)
            })

            socket.on('disconnect', () => {
                console.log({ status: 'socket disconnected' })
                setSocketId('')
                setSocketOn(false)
            })
        }
    }, [socket])

    return (
        <div className="App">
            <HashRouter>
                <Suspense fallback={<Loader />}>
                    <socketContext.Provider value={{ socket, socketOn, socketId }}>
                        <Routes>
                            <Route path="/*" element={<LandingPage />} />
                            <Route path="/main/*" element={<TheLayout />} />
                            <Route path="/console/*" element={<Console />} />
                            <Route path="/404" element={<div>404 not found</div>} />
                        </Routes>
                    </socketContext.Provider>
                </Suspense>
            </HashRouter>
        </div>
    )
}

export default App
