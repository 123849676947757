import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { removeDup } from '../../lib/fn'
import axios from '../../lib/axios'
import { RepliedDetail } from '../../components/main/interactiveSec/InteractiveSec'

export interface Question {
    _id: string
    from: {
        _id: string
        name: string
        avatar: string
        company: string
        googler: boolean
        helper: boolean
    }
    type: string
    content: string
    approved: boolean
    createTime: number
    room: string
    to: RepliedDetail | null
    response: Question[]
}

interface QuestionState {
    error: string | null
    questions: Question[]
    loading: boolean
    comingData: boolean
}

export const onGetHistoryQuestions = createAsyncThunk(
    'question/getHistoryQuestions',
    async (args: { _id: string; room: string }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/question/historyQuestions/${args._id}`, {
                questionCount: process.env.REACT_APP_GET_QUESTION_COUNT,
                room: args.room,
            })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetRestHistoryQuestions = createAsyncThunk(
    'question/getRestHistoryQuestions',
    async (args: { _id: string; localQuestionCount: number; room: string }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/question/restHistoryQuestions/${args._id}`, {
                questionCount: process.env.REACT_APP_GET_QUESTION_COUNT,
                localQuestionCount: args.localQuestionCount,
                room: args.room,
            })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: QuestionState = {
    questions: [],
    error: null,
    loading: false,
    comingData: false,
}

export const questionSlice = createSlice({
    name: 'questionSlice',
    initialState,
    reducers: {
        addLocalQuestion(state, action) {
            if (action.payload.to && action.payload.type === 'response') {
                state.questions[action.payload.to.repliedIndex] = {
                    ...state.questions[action.payload.to.repliedIndex],
                    response: [...state.questions[action.payload.to.repliedIndex].response, action.payload],
                }
            } else state.questions = [...state.questions, action.payload]
        },
        updateLocalQuestion(state, action) {
            const idx = state.questions.findIndex((ele) => {
                return ele._id === action.payload._id
            })

            if (idx !== -1) {
                if (action.payload.response.length > 0) {
                    const mergedQuestionArr = removeDup<Question>(
                        [...state.questions[idx].response, ...action.payload.response],
                        (m) => m._id
                    ).sort((a, b) => a.createTime - b.createTime)
                    state.questions[idx] = {
                        ...state.questions[idx],
                        response: [...mergedQuestionArr],
                    }
                } else return
            } else {
                state.questions = [...state.questions, action.payload]
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(onGetHistoryQuestions.pending, (state, _action) => {
            state.loading = true
        })
        builder.addCase(onGetHistoryQuestions.fulfilled, (state, action) => {
            state.questions = action.payload.questions.reverse()
            state.error = null
            state.loading = false
            state.comingData = action.payload.questions.length > 0
        })
        builder.addCase(onGetHistoryQuestions.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
        builder.addCase(onGetRestHistoryQuestions.pending, (state, _action) => {
            state.loading = true
        })
        builder.addCase(onGetRestHistoryQuestions.fulfilled, (state, action) => {
            const reverseQuestions = action.payload.questions.reverse()
            state.questions = [...reverseQuestions, ...state.questions]
            state.error = null
            state.loading = false
            state.comingData = action.payload.questions.length > 0
        })
        builder.addCase(onGetRestHistoryQuestions.rejected, (state, action) => {
            state.error = (action.payload as { error: string }).error
        })
    },
})

export const { addLocalQuestion, updateLocalQuestion } = questionSlice.actions
export default questionSlice.reducer
